<template>
    <div>
        <Header></Header>

        <div id="myCarousel" class="carousel slide">
            <div class="carousel-inner">
                <div class="item active">
                    <img src="./../assets/images/aboutus_banner.jpeg" alt="First slide" style="width:100%">
                </div>
            </div>
        </div>

        <div class="new_list">
            <div class="new_list_item" v-for="(item, index) in news_list.slice(0,4)" :key="index" @click="to_detail(item)">
                <div class="new_list_item_div">
                    <div class="new_list_item_image">
                        <img :src="item.pic" alt style>
                    </div>
                </div>
                <div class="new_list_item_title">{{item.title}}</div>
                <div class="new_list_item_time">{{item.date}}</div>
                <div class="new_list_item_content">{{item.content.slice(0,80) + '...'}}</div>
                <div class="new_list_item_icon">首页新闻</div>
            </div>
        </div>

        <main class="show_met_21_6_12 page-content" m-id="12">
            <article class="met-show-body panel m-b-0" boxmh-mh>
                <div class="container">
                    <section class="met-editor clearfix">
                        <p style="text-align: center"><img src="./../assets/images/aboutUs.jpeg" title="1552529097892818.jpg"></p>
                    </section>
                </div>
            </article>
        </main>

        <section class="message_form_met_16_1_26 animsition" m-id="26" m-type="nocontent">
            <div class="container">
                <div class="row">
                    <div class="col-md-8 col-lg-6 offset-md-2 offset-lg-3 message_form message-list-no" m-id="26" m-type="message_form">
                        <div class="row">
                            <div class="met-message-submit panel panel-body m-b-0" boxmh-h>
                                <div class="met-form met-form-validation fv-form-bootstrap4 fv-form">
                                    <button type="submit" class="fv-hidden-submit" style="display: none; width: 0px; height: 0px;"></button>
                                    <input type="hidden" name="lang" value="cn">
                                    <div class="form-group"><input name="para1" class="form-control" type="text" placeholder="姓名" v-model="name"></div>
                                    <div class="form-group"><input name="para2" class="form-control" type="tel" placeholder="联系方式" v-model="tel"></div>
                                    <div class="form-group"><input name="para3" class="form-control" type="email" placeholder="Email" v-model="email"></div>
                                    <!--                                    <div class="form-group">-->
                                    <!--                                        <select name="para4" class="form-control" v-model="type">-->
                                    <!--                                            <option value='' disabled selected style='display:none;'>留言类型</option>-->
                                    <!--                                            <option value="商务合作">商务合作</option>-->
                                    <!--                                            <option value="意见建议">意见建议</option>-->
                                    <!--                                        </select>-->
                                    <!--                                    </div>-->
                                    <div class="form-group">
                                        <textarea name="para5" class="form-control" placeholder="留言内容" rows="5" v-model="desc"></textarea>
                                    </div>
                                    <div class="form-group m-b-0">
                                        <button type="submit" class="btn btn-primary btn-block btn-squared" style="background-color: #000" @click="submit">
                                            提交留言
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </section>

        <Footer></Footer>
    </div>
</template>

<script>
  import Header from '@/components/Header'
  import Footer from '@/components/Footer'

  export default {
    components: {
      Header,
      Footer
    },
    data() {
      return {
        news_list: [],
        name: '',
        tel: '',
        email: '',
        desc: '',
        submitDataString: ''
      }
    },
    mounted() {
      this.getArticles();
    },
    methods: {
      getArticles() {
        this.$api.getArticles().then(res => {
          this.news_list = res.data.data;
        })
      },
      to_detail(item) {
        this.$router.push({path: '/new_detail', query: {title: item.title, data: item.date, content: item.content}});
      },
      submit() {
        if (!this.name) {
          alert('请填写姓名');
          return false;
        }
        if (!this.tel) {
          alert('请填写联系方式');
          return false;
        }
        if (!this.email) {
          alert('请填写邮件');
          return false;
        }
        if (!this.desc) {
          alert('请填写留言内容');
          return false;
        }

        let data = {
          name: this.name,
          tel: this.tel,
          email: this.email,
          content: this.desc
        };

        if (JSON.stringify(data) === this.submitDataString) {
          alert('请不要重复提交');
          return false;
        }

        this.$api.createMessages(data).then(() => {
          this.submitDataString = JSON.stringify(data);
          alert('提交成功')
        }).catch(() => {
          alert('提交失败，请重新尝试')
        });
      }
    }
  }
</script>

<style scoped>

    @import "../assets/css/show_cn.css";

    .carousel {
        margin-top: 80px !important;
    }

    .new_list {
        margin: 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: center;
    }

    .new_list_item {
        margin: 30px;
        width: 362px;
        height: 386px;
        position: relative;
        padding: 10px 0;
    }

    .new_list_item_div {
        width: 326px;
        height: 148px;
        overflow: hidden;
        margin: 0 auto;
    }

    .new_list_item_image {
        width: 100%;
        height: 100%;
        background-size: 100% 100%;
        background-repeat: no-repeat;
    }

    .new_list_item_title {
        width: 326px;
        font-size: 16px;
        font-weight: 400;
        margin: 10px auto 0;
        text-align: left;
        color: #333;
        line-height: 24px;
    }

    .new_list_item_time {
        width: 326px;
        font-size: 14px;
        margin: 0 auto;
        text-align: left;
        color: #999;
    }

    .new_list_item_content {
        width: 326px;

        margin: 18px auto 0;
        text-align: left;
        font-size: 14px;
        color: #666;
        line-height: 1.7;
    }

    .new_list_item_icon {
        display: inline-block;
        position: absolute;
        bottom: 20px;
        left: 20px;
        height: 26px;
        line-height: 26px;
        max-width: 100%;
        padding: 0 8px;
        border: 1px solid #e5e5e5;


        border-radius: 5px;
        color: #999;
        font-size: 12px;
        word-break: keep-all;
        overflow: hidden;
        z-index: 2;
    }

    .new_list_item:hover {
        transition: all .4s ease-out;
        box-shadow: 0 0 4px gray;
    }

    .new_list_item:hover .new_list_item_image {
        transform: scale(1.2);
        transition: all .4s ease-out;
    }

    .new_list_item:hover .new_list_item_title {
        color: rgb(65, 119, 219);
    }
</style>
